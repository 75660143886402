import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Tabs, Tab } from "@nulogy/components";
import { config } from "../../playgrounds/tabs";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const Example = makeShortcode("Example");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Tabs`}</h1>
    <p>{`For navigating between sections of related content.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h3>{`Overflow`}</h3>
    <p>{`When the width of the individual Tab components exceed the total width of the Tabs component, scrolling becomes enabled and buttons are placed on the edges to make it easier to scroll to off-screen Tabs on mobile devices.`}</p>
    <Example componentName="tabs" scope={{
      Tabs,
      Tab
    }} example="Overflow" mdxType="Example" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      